<template>
  <div class="g-container">
    <div ref="active_popup">
      <Popup v-click-outside="closePopup" :rightPosition="popup.rightPosition" :user-id="popup.partnerId"
        :show-popup="popup.show" @onEdit="editPartner(popup.partnerId)" @onDelete="popup.confirm = true"></Popup>
      <ConfirmPopup v-if="isAuthUserAsAdmin || isAuthUserRegional" :show="popup.confirm"
        text="Восстановление удаленного партнера невозможно. Продолжить?" @close="popup.confirm = false"
        @confirm="deletePartner(popup.partnerId)"></ConfirmPopup>
      <ConfirmPopup v-if="(isAuthUserAsAdmin || isAuthUserRegional) && isItemsDelete" :show="popup.confirmSelected"
        text="Восстановление удаленных партнеров невозможно. Продолжить?" @close="popup.confirmSelected = false"
        @confirm="deletePartners()"></ConfirmPopup>
    </div>
    <div class="users-page">
      <h2 class="users-page__title">Список партнеров</h2>
      <div class="flex justify-between items-start">
        <button class="btn btn-green" @click="createPartner" v-if="isAuthUserAsAdmin || isAuthUserRegional">
          Новый партнер
        </button>
        <button class="btn btn-gray" style="margin-left: 5px"
          v-if="(isAuthUserAsAdmin || isAuthUserRegional) && isItemsDelete" @click="confirmSelectedDelete">
          Удалить партнеров
        </button>
        <Search :is-not-found="isNotFound" :text="queryString" @query="changeQueryString" place-holder="Поиск партнера">
        </Search>
      </div>
      <div class="table-wrapper" v-if="partners.length">
        <table class="users-table">
          <thead class="users-table__head">
            <tr>
              <th>
                <div class="users-table__box">
                  <input type="checkbox" id="active" class="form-input-check" v-model="isSelectAllItems"
                    @change="selectAllItems()">
                  <label for="active" class="form-label form-label-check form-label-check_black" />
                </div>
              </th>
              <th @click="order = changedOrderState('name', '-name', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Название</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-name', 'opacity-0': !['name', '-name'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th @click="order = changedOrderState('type', '-type', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Тип партнера</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-type', 'opacity-0': !['type', '-type'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th @click="order = changedOrderState('is_show_on_map', '-is_show_on_map', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Виден на карте</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-is_show_on_map', 'opacity-0': !['is_show_on_map', '-is_show_on_map'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th @click="order = changedOrderState('is_sertified', '-is_sertified', order)">
                <div class="users-table__box">
                  <span class="users-table__title">С сертификатом</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-is_sertified', 'opacity-0': !['is_sertified', '-is_sertified'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th @click="order = changedOrderState('business_regions', '-business_regions', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Бизнес регион</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-business_regions', 'opacity-0': !['business_regions', '-business_regions'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th @click="order = changedOrderState('curator', '-curator', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Куратор</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-curator', 'opacity-0': !['curator', '-curator'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th @click="order = changedOrderState('additional_curators', '-additional_curators', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Доп. куратор</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-additional_curators', 'opacity-0': !['additional_curators', '-additional_curators'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th @click="order = changedOrderState('created', '-created', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Дата создания</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-created', 'opacity-0': !['created', '-created'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th @click="order = changedOrderState('updated', '-updated', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Дата изменения</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-updated', 'opacity-0': !['updated', '-updated'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th @click="order = changedOrderState('id', '-id', order)">
                <div class="users-table__box">
                  <span class="users-table__title">Идентификатор</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-id', 'opacity-0': !['id', '-id'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in partners" :key="item.partner.id">
              <td>
                <div class="users-box">
                  <input type="checkbox" :id="`${item.partner.id}`" class="form-input-check" v-model="item.selected"
                    @change="itemSelected">
                  <label :for="`${item.partner.id}`" class="form-label form-label-check form-label-check_black" />
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `name${item.partner.id}`)">
                <div class="users-box" :ref="`name${item.partner.id}`">
                  <span class="users-table__desc">{{ item.partner.name }}</span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `type${item.partner.id}`)">
                <div class="users-box" :ref="`type${item.partner.id}`">
                  <span class="users-table__desc" v-for="typeId in item.partner.types" :key="typeId">
                    {{ partnerTypes.find(type => type.id === typeId).name }}
                  </span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `mapVisible${item.partner.id}`)">
                <div class="users-box" :ref="`mapVisible${item.partner.id}`">
                  <span class="users-table__desc">{{ item.partner.is_show_on_map ? 'Да' : 'Нет' }}</span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `mapVisible${item.partner.id}`)">
                <div class="users-box" :ref="`mapVisible${item.partner.id}`">
                  <span class="users-table__desc">{{ item.partner.is_sertified ? 'Да' : 'Нет' }}</span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `regions${item.partner.id}`)">
                <div class="users-box" :ref="`regions${item.partner.id}`">
                  <span v-for="region in item.partner.business_regions_names" :key="region" class="users-table__desc">
                    {{ region }}
                  </span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `curators${item.partner.id}`)">
                <div class="users-box" :ref="`curators${item.partner.id}`">
                  <span class="users-table__desc truncate">{{ item.partner.curator_name }}</span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `additionalCurators${item.partner.id}`, true)">
                <div class="users-box" :ref="`additionalCurators${item.partner.id}`">
                  <span v-for="curator in item.partner.additional_curators_names" :key="curator"
                    class="users-table__desc truncate">
                    {{ curator }}
                  </span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `mapVisible${item.partner.id}`)">
                <div class="users-box" :ref="`mapVisible${item.partner.id}`">
                  <span class="users-table__desc">{{ parseDate(item.partner.created) }}</span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `mapVisible${item.partner.id}`)">
                <div class="users-box" :ref="`mapVisible${item.partner.id}`">
                  <span class="users-table__desc">{{ parseDate(item.partner.updated) }}</span>
                </div>
              </td>
              <td @contextmenu.prevent="showPopup(item.partner.id, `id${item.partner.id}`)">
                <div class="users-box" :ref="`id${item.partner.id}`">
                  <span class="users-table__desc truncate">{{ item.partner.id }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <FullPagination :num="pagination.page" :pagesCount="pagination.countPages"
        :perPage="String(this.pagination.perPage)" @changeCurrent="changeCurrent" @currentPerPage="currentPerPage">
      </FullPagination>
      <div class="users-not" v-if="partners.length === 0 && queryString">
        <p class="users-not__title">К сожалению, по запросу «{{ queryString }}» ничего не найдено.</p>
        <p class="users-not__desc">Проверьте правильность запроса и повторите поиск.</p>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import FullPagination from '@/components/FullPagination.vue';
import Search from '@/components/Search.vue';
import Popup from '@/components/FacilityPopup.vue';
import ConfirmPopup from '@/components/ConfirmPopup.vue';

export default {
  name: 'Partners',
  directives: {
    ClickOutside
  },
  components: {
    Search,
    FullPagination,
    Popup,
    ConfirmPopup
  },
  data() {
    return {
      pagination: {
        page: 1,
        perPage: this.$route.params.other ? Number(this.$route.params.other.page_size) : 10,
        countPages: 1,
        countItems: 0
      },
      queryString: (this.$route.params.other && this.$route.params.other.search_query) ? this.$route.params.other.search_query : '',
      isNotFound: false,
      popup: {
        show: false,
        confirm: false,
        confirmSelected: false,
        partnerId: null,
        rightPosition: false
      },
      partners: [],
      isSelectAllItems: false,
      users: [],
      order: null,
      params: {}
    }
  },
  async created() {
    this.$store.dispatch('partner/fetchPartnerTypes');
    await this.fetchUsers();

    if (this.$route.params && this.$route.params.other) {
      this.pagination.page = this.$route.params.other.page + 1;
    }
    await this.fetchPartners();
  },
  watch: {
    async order() {
      await this.fetchPartners();
    },
    async queryString(newVal, oldVal) {
      if (newVal !== oldVal && (newVal.length > 2 || newVal === '')) {
        await this.fetchPartners();
      }
    }
  },
  methods: {
    changedOrderState(ascName, descName, currentState) {
      if (currentState !== ascName && currentState !== descName) {
        return ascName;
      }
      if (currentState === ascName) {
        return descName;
      }
      if (currentState === descName) {
        return null;
      }
    },
    closePopup() {
      this.popup.show = false;
    },
    async deletePartner(id) {
      this.popup.confirm = false;
      try {
        await this.$services.PartnerService.deletePartners([id]);
        this.$services.MessageService.success('Партнер успешно удален');
        setTimeout(() => {
          this.fetchPartners();
        }, 1000);
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при удалении партнера');
      }
    },
    async deletePartners() {
      this.popup.confirmSelected = false;
      try {
        const itemsDeleted = this.partners.filter(item => item.selected).map(value => value.partner.id);
        await this.$services.PartnerService.deletePartners(itemsDeleted);
        this.$services.MessageService.success('Партнеры успешно удалены');
        this.pagination.page = 1;
        setTimeout(() => {
          this.fetchPartners();
        }, 1000);
      } catch (error) {
        console.log(error);
        this.$services.MessageService.error('Ошибка при удалении партнеров');
      }
    },
    createPartner() {
      this.$router.push({ name: 'partner-create' });
    },
    changeQueryString(string) {
      this.queryString = string || '';
    },
    async fetchPartners() {
      this.params = {
        page: this.pagination.page - 1,
        page_size: this.pagination.perPage
      };

      const ordering = this.order;
      if (this.queryString.length === 0 && !ordering) {
        this.params = {
          ...this.params
        };
      } else if (this.queryString.length === 0 && ordering) {
        this.params = {
          ...this.params,
          ordering
        };
      } else if (this.queryString.length > 0 && !ordering) {
        this.params = {
          ...this.params,
          search_query: this.queryString
        };
      } else if (this.queryString.length > 0 && ordering) {
        this.params = {
          ...this.params,
          search_query: this.queryString,
          ordering
        };
      }

      const partnersData = await this.$services.PartnerService.getPartners(this.params);
      this.pagination.countItems = partnersData.count;
      this.pagination.countPages = Math.ceil(partnersData.count / this.pagination.perPage) || 1;
      this.isSelectAllItems = false;
      this.partners = partnersData.results.map(partner => ({ selected: this.isSelectAllItems, partner }));
    },
    async fetchUsers() {
      const data = await this.$services.UserService.getUsers({ page_size: 10000 });
      this.users = data.results;
    },
    async changeCurrentPage(page) {
      this.page = page;
      await this.fetchPartners();
    },
    showPopup(partnerId, ref, rightPosition = false) {
      const element = this.$refs[ref][0];
      if (element) {
        element.appendChild(this.$refs['active_popup']);
        this.popup.partnerId = partnerId;
        this.popup.show = true;
        this.popup.rightPosition = rightPosition;
      }
    },
    confirmSelectedDelete() {
      this.popup.confirmSelected = true;
    },
    editPartner(partnerId) {
      this.$router.push({
        name: 'partner-edit',
        params: { id: partnerId, other: this.params }
      });
    },
    parseDate(date) {
      if (date) {
        const dateObj = new Date(date);
        const addZero = num => (num > 9 ? num : `0${num}`);
        return `${addZero(dateObj.getDate())}.${addZero(dateObj.getMonth() + 1)}.${dateObj.getFullYear()}`;
      }
      return '';
    },
    selectAllItems() {
      this.partners = this.partners.map(value => ({ selected: this.isSelectAllItems, partner: value.partner }));
    },
    itemSelected() {
      this.isSelectAllItems = this.partners.filter(item => item.selected).length === this.partners.length;
    },
    /**
     * Событие выбора страницы
     * @param {number} page page number
     */
    async changeCurrent(page) {
      this.pagination.page = page;
      await this.fetchPartners();
    },
    /**
     * Событие выбора количества элементов на странице
     * @param {string} items количество елементов на странице
     */
    async currentPerPage(items) {
      this.pagination.page = 1;
      this.pagination.perPage = items === 'Все' ? this.pagination.countItems : Number(items);
      await this.fetchPartners();
    }
  },
  computed: {
    partnerTypes() {
      return this.$store.getters['partner/getPartnerTypes'];
    },
    businessRegions() {
      return this.$store.getters['business_region/getRegions'];
    },
    isAuthUserAsAdmin() {
      const adminRoleId = this.$store.getters['user/getAdminRoleId'];
      const authUser = this.$store.getters['auth/getAuthUser'];
      return authUser.role_id === adminRoleId;
    },
    isAuthUserRegional() {
      const regionalRoleId = this.$store.getters['user/getRegionalRoleId'];
      const authUser = this.$store.getters['auth/getAuthUser'];
      return authUser.role_id === regionalRoleId;
    },
    isItemsDelete() {
      return this.partners.filter(item => item.selected).length;
    },
    getSearch() {
      return this.$store.getters['partner/getSearch'];
    }
  }
}
</script>

<style scoped>
.form-input-check {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-label-check {
  position: relative;
  display: inline-block;
  padding-left: 29px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

.form-label-check::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  background: #fff;
  border: 1px solid #4E4E4E;
}

.form-label-check::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: url("../../assets/images/svg/checked-black.svg") no-repeat;
  opacity: 0;
  z-index: 2;
}

.form-label-check_black::after {
  background: url("../../assets/images/svg/checked.svg") no-repeat;
}

.form-input-check[type="checkbox"]:checked+.form-label-check_black::before {
  background-color: #000;
}

.form-input-check[type="checkbox"]:checked+.form-label-check::after {
  opacity: 1;
}
</style>
